import * as React from 'react'
import { mailAddress, phoneNumber } from '@constants/configuration'
import SvgMailImg from '@assets/icons/mail_img.svg'
import { IconTextLink } from '@controls/buttons'
import styles from './DealerContacts.module.css'
import { LinkWhatsapp, LinkViber, LinkPhone } from '@controls/buttons'
import { FlexContainer } from '@layout/FlexContainer'
import { buildEmailLink } from '@constants/links'

export const DealerContacts: React.FC = () => {
  return (
    <FlexContainer alignItemsCenter className={styles.dealerContactsContainer}>
      <IconTextLink className={styles.mail} href={buildEmailLink(mailAddress)} text={mailAddress} target="_blank">
        <img src={SvgMailImg} alt="email" />
      </IconTextLink>
      <FlexContainer alignItemsCenter justifyContentCenter fitWidth className={styles.rowSocialMedia}>
        <FlexContainer alignItemsCenter justifyContentCenter fixedDirection responsiveGap fitWidth>
          <LinkWhatsapp phoneNumber={phoneNumber} size="large" colored />
          <LinkViber phoneNumber={phoneNumber} size="large" colored />
        </FlexContainer>
        <LinkPhone phoneNumber={phoneNumber} />
      </FlexContainer>
    </FlexContainer>
  )
}
