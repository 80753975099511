import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { shuffleArray } from '@model/utils'
import { CallbackRequestSchema, TopicEnum } from '@api/endpoints/forms/types'

import consultant1 from '@assets/images/team/anastassia.png'
import consultant2 from '@assets/images/team/raimond.png'
import consultant3 from '@assets/images/team/ravil.png'
import formStyles from '@assets/css/forms.module.css'
import { Button } from '@mui/material'
import { useLanguage } from '@hooks/useLanguage'
import { useMutationRequestCallback } from '@hooks/ReactQuery/forms'
import { useSnackbarActions } from '@hooks/useSnackbarActions'
import { useFormTrackedWrapper } from '@hooks/Tracking'
import { FormTextField, useZodForm } from '@controls/Form'
import { FlexContainer } from '@layout/FlexContainer'
import styles from './ContactForm.module.css'
import { sendConversionEvent } from '@api/tracking'

export const FormValuesSchema = CallbackRequestSchema.omit({ language: true, topic: true })
export type FormValues = z.infer<typeof FormValuesSchema>

type Props = {
  topic: TopicEnum
}

export const ContactForm: React.FC<Props> = ({ topic }) => {
  const { t } = useTranslation()
  const { enqueueSuccessMsg } = useSnackbarActions()
  const { language } = useLanguage()
  const [shuffledImages, setShuffledImages] = React.useState<Array<string>>([])

  const handleSuccess = (): void => {
    enqueueSuccessMsg(t('pages.contactStrip.success'))
    form.reset()
  }

  const handleInvalidPhoneNumber = (): void => {
    form.setError('phoneNumber', { message: t('global.form_errors.invalid_phone_number') })
  }

  const createCallRequest = useMutationRequestCallback({
    onSuccess: handleSuccess,
    onInvalidPhoneNumber: handleInvalidPhoneNumber
  })

  const form = useZodForm({ schema: FormValuesSchema })

  const submitHandler = (values: FormValues): void => {
    sendConversionEvent()
    createCallRequest.mutate({ topic, language, ...values })
  }

  React.useEffect(() => {
    if (createCallRequest.data) {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCallRequest.data])

  React.useEffect(() => {
    const shuffled = shuffleArray([consultant1, consultant2, consultant3])
    setShuffledImages([...shuffled])
  }, [])

  return (
    <FlexContainer vertical bordered className={styles.contactFormContainer}>
      <FlexContainer justifyContentCenter alignItemsCenter>
        <div className={styles.titleContainerText}>
          <h2>{t('pages.contactStrip.title')}</h2>
          {/*
          <h3>{t('pages.contactStrip.subtitle')}</h3>
          */}
        </div>
      </FlexContainer>
      <FlexContainer justifyContentCenter className={styles.photoContainer}>
        {shuffledImages.slice(0, 3).map((image, index) => (
          <img key={`consultant-${index}`} src={image} alt="consultant image" />
        ))}
      </FlexContainer>
      <h4 className={styles.subtitle}>{t('pages.contactStrip.subtitle')}</h4>

      <form onSubmit={useFormTrackedWrapper({ formName: 'contact', topic }, form, submitHandler).handleSubmit}>
        <FlexContainer responsiveGap>
          <FormTextField
            control={form.control}
            className={formStyles.required}
            type="name"
            name="name"
            label={t('global.forms.customer.fields.name')}
            disabled={createCallRequest.isPending}
          />
          <FormTextField
            control={form.control}
            name="phoneNumber"
            type="phone"
            className={formStyles.required}
            label={t('global.forms.customer.fields.phone')}
            disabled={createCallRequest.isPending}
          />
          <FormTextField
            control={form.control}
            name="email"
            type="email"
            className={formStyles.required}
            label={t('global.forms.customer.fields.email')}
            disabled={createCallRequest.isPending}
          />
          <Button variant="contained" type="submit" disabled={createCallRequest.isPending} fullWidth>
            {t('pages.contactStrip.button')}
          </Button>
        </FlexContainer>
      </form>
    </FlexContainer>
  )
}
